<template>
    <div class="block" v-if="!loading">
        <ClassicBreadCrumb :title="cylinder.nom" v-if="cylinder" />
        <div class="top-block flex space-between">
            <basic-title>{{ $t("KIBOLT_ACCESS") }}</basic-title>
            <basic-button
                class="override-button override-option dropdown-toggle"
                color="blue"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {{ $t("ADVANCED_OPTIONS") }}
            </basic-button>
            <ul class="dropdown-menu dropdown-menu-end">
                <a
                    class="dropdown-item"
                    @click="v1Modal"
                    v-if="cylinder.residence_simple == 0"
                >
                    {{ $t("MOVING") }}
                </a>
                <a class="dropdown-item" @click="replaceCylinder">
                    {{ $t("kibolt.replace_cylinder") }}
                </a>
                <span @click="deleteCylinder" class="dropdown-item">
                    {{ $t("button.delete") }}
                </span>
            </ul>
        </div>
        <div class="body-block">
            <div class="flex block-step justify-center">
                <div class="block-left">
                    <form @submit.prevent="submit">
                        <div class="form-elem">
                            <label>{{ $t("kibolt.name_door") }}</label>
                            <basic-input
                                v-model="doorName"
                                name="doorName"
                                :rules="isNotEmpty"
                                class="form-input"
                            />
                        </div>
                        <div class="form-elem align-items-initial">
                            <label>{{ $t("SERIAL_NUM") }}</label>
                            <div>
                                <p>{{ cylinder.serial }}</p>
                            </div>
                        </div>
                        <div class="form-elem">
                            <label>{{ $t("kibolt.type_cylinder") }}</label>
                            <span>{{ getLibelle }}</span>
                        </div>
                        <div class="form-elem">
                            <label>{{ $t("kibolt.size_cylinder") }}</label>
                            <span
                                >{{ cylinder.dim_ext }} /
                                {{ cylinder.dim_int }}</span
                            >
                        </div>
                        <div class="form-elem align-items-initial">
                            <label>{{ $t("COMMENT") }}</label>
                            <basic-textarea
                                v-model="comments"
                                class="form-textarea"
                                id="story"
                                name="story"
                            />
                        </div>
                        <p
                            @click="displayShowMore = !displayShowMore"
                            class="show-more"
                        >
                            <span class="display-more">{{
                                $t("SHOW_MORE")
                            }}</span>
                            <i
                                class="fas fa-chevron-circle-down toggleUpDown"
                                :class="{ rotate: displayShowMore }"
                            ></i>
                        </p>
                        <Transition name="down">
                            <div v-if="displayShowMore">
                                <div class="form-elem">
                                    <label>{{
                                        $t("kibolt.version_soft")
                                    }}</label>
                                    <span>{{ cylinder.version }}</span>
                                </div>
                                <div class="form-elem">
                                    <label>{{
                                        $t("kibolt.version_boot")
                                    }}</label>
                                    <span>{{ cylinder.boot }}</span>
                                </div>
                            </div>
                        </Transition>
                        <div class="block-submit">
                            <basic-button
                                type="submit"
                                class="override-button"
                                @click="saveCylinder"
                                :disabled="!isDataChanged"
                            >
                                {{ $t("button.register") }}
                            </basic-button>
                            <basic-button
                                type="submit"
                                class="override-button cancel"
                                @click="majCylinder"
                            >
                                {{ $t("TO_UPDATE") }}
                            </basic-button>
                        </div>
                    </form>
                </div>
                <div class="block-right text-center">
                    <img
                        class="img-cylinder-details"
                        :src="getImageCylinder"
                        alt="cylinder"
                    />
                </div>
            </div>
        </div>
    </div>
    <div v-if="loading" class="loader-wrapper">
        <Loader />
    </div>
    <Teleport to="body">
        <div v-if="modalState" class="modal-mask">
            <div class="modal-container">
                <div class="title"></div>
                <validation :question="errorMessage" :valid="fnValid">
                    <template #leftButton>
                        {{ $t("button.delete") }}
                    </template>
                    <template #rightButton>
                        {{ $t("button.cancel") }}
                    </template>
                </validation>
            </div>
        </div>
    </Teleport>
</template>

<script>
"use strict"
import BasicTitle from "@/components/basic/BasicTitle"
import BasicInput from "@/components/basic/BasicInput.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import BasicTextarea from "@/components/basic/BasicTextarea.vue"
import ClassicBreadCrumb from "@/components/entities/ClassicBreadCrumb.vue"
import Validation from "@/components/Modal/Validation.vue"
import Loader from "@/components/basic/Loader.vue"

import { getResidenceSummaryById } from "@/services/intratone/residence"
import { getStairwellSummaryById } from "@/services/intratone/stairwell"
import { getCylinderAccess } from "@/services/intratone/access.js"
import { updateCylinderAccess } from "@/services/intratone/access.js"
import { deleteCylinderAccess } from "@/services/intratone/access.js"

import { useGetImageCylinder } from "@/composables/kibolt.js"
import { useCloseModal, useModalState } from "@/composables/modal.js"

import { useToast } from "vue-toastification"
const toast = useToast()

import v1mixin from "@/mixins/v1.js"
import { nextTick } from "@vue/runtime-core"

export default {
    name: "CylinderDetails",
    inject: ["lodash", "emitter"],
    mixins: [v1mixin],
    components: {
        BasicInput,
        BasicButton,
        BasicTitle,
        BasicTextarea,
        ClassicBreadCrumb,
        Validation,
        Loader,
    },
    data() {
        return {
            breadcrumb: {},
            route: this.$route,
            cylinder: {},
            doorName: "",
            comments: "",
            displayShowMore: false,
            isRotate: false,
            errorMessage: "",
            fnValid: null,
            loading: true,
            breadCrumbToPush: null,
        }
    },
    computed: {
        modalState() {
            const { modalState } = useModalState()
            return modalState.value
        },
        getImageCylinder() {
            return useGetImageCylinder({ type: this.cylinder.type_id })
        },
        getLibelle() {
            let libelle = ""
            switch (parseInt(this.cylinder.type_id)) {
                case 1:
                    libelle = this.$t("kibolt.simple_cylinder")
                    break
                case 2:
                    libelle = this.$t("kibolt.double_cylinder")
                    break
                case 3:
                    libelle = this.$t("kibolt.button_cylinder")
                    break
                default:
                    libelle = this.$t("kibolt.double_cylinder")
                    break
            }
            return libelle
        },
        isKiprogCanBeUsed() {
            return this.$store.getters["miscellaneous/getCanKiprogBeUsed"]
        },
        getDevice() {
            return this.$store.getters["account/getDevice"]
        },
        isDataChanged() {
            return (
                (this.cylinder.nom !== this.doorName ||
                    this.cylinder.comments !== this.comments) &&
                this.doorName
            )
        },
    },
    watch: {
        isKiprogCanBeUsed: {
            immediate: true,
            handler(value) {
                if (!value) {
                    this.openModal("alert", {
                        title: "deviceNotCompatible",
                        refuse: () => {
                            this.$store.commit(
                                "miscellaneous/setCanKiprogBeUsed",
                                true
                            )
                        },
                    })
                }
            },
        },
    },

    async mounted() {
        this.loading = true

        if (this.route.params.idcylinderaccess) {
            const { data } = await getCylinderAccess(
                this.route.params.idcylinderaccess
            )
            if (!data) {
                this.$router.push({
                    path: "/error/404",
                })
            }
            this.cylinder = data
            this.doorName = this.cylinder.nom
            this.comments = this.cylinder.comments

            this.breadcrumb.cylinder = {
                name: this.cylinder.nom,
                id: "",
                last: true,
            }
        }

        this.loading = false
    },
    methods: {
        async v1Modal() {
            await nextTick()
            this.openV1Modal(
                `data/cylinder_move/id_acces=${this.route.params.idcylinderaccess}`,
                "Middle_Zone"
            )
        },
        async saveCylinder() {
            this.loading = true

            const params = {
                name: this.doorName,
                comments: this.comments,
            }
            const { data } = await updateCylinderAccess({
                id: this.route.params.idcylinderaccess,
                params: params,
            })
            const type = this.route.params.type
            this.$router.push({
                name: type[0].toUpperCase() + type.slice(1),
                params: {
                    id: this.route.params.id,
                },
            })

            this.loading = false
        },
        majCylinder() {
            const type = this.route.params.type
            this.$router.push({
                path: `/${type}/${this.route.params.id}/cylinder/${this.route.params.idcylinderaccess}/update`,
            })
        },
        replaceCylinder() {
            const type = this.route.params.type
            this.$router.push({
                path: `/${type}/${this.route.params.id}/cylinder/${this.route.params.idcylinderaccess}/replace`,
            })
        },

        async deleteCylinder() {
            this.openModal("validation", {
                title: "delete_cylinder?_is_irreversible",
                validateButton: this.$t("button.delete"),
                valid: async () => {
                    this.loading = true
                    this.closeModal()
                    await deleteCylinderAccess({
                        id: this.route.params.idcylinderaccess,
                    }).then((response) => {
                        if (response.error == 0) {
                            const type = this.route.params.type
                            this.$router.push({
                                name: type[0].toUpperCase() + type.slice(1),
                                params: {
                                    id: this.route.params.id,
                                },
                            })
                        }
                    })

                    this.loading = false
                },
                refuse: () => {
                    this.closeModal()
                },
            })
        },

        isNotEmpty(value) {
            if (value === "" || value === null || value === undefined) {
                return this.$t("input.empty")
            }
            return true
        },
    },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.block {
    padding: 20px 80px;
    display: flex;
    flex-direction: column;
    gap: 1em;

    @media screen and (max-width: 1024px) {
        padding: 2em 20px;
    }

    .top-block {
        margin: 0;
    }

    .body-block {
        padding-top: 3em;
        span,
        p {
            font-family: $font-avenir-book;
        }
    }
}

.loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-block {
    margin-top: 0.5rem;
    margin-bottom: 3.5rem;
}
.cancel {
    width: 7.5rem;
}

.show-more {
    cursor: pointer;
}

.dropdown-item-menu {
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    width: max-content !important;
}
.dropdown-item {
    cursor: pointer;
    font-size: $normal;
    font-family: $font-avenir-medium;
}
.toggleUpDown {
    transition: transform 0.3s ease-in-out !important;
}

.toggleUpDown.rotate {
    transform: rotate(180deg);
}

.down-enter-active,
.down-leave-active {
    transition: opacity 0.2s ease-out;
}

.down-enter-from,
.down-leave-to {
    opacity: 0;
}

.override-option {
    width: 10rem;
}
.cancel {
    background-color: $blue-neutral;
    margin: 0 1rem;
}
.cancel:hover {
    background-color: $blue-light;
}
form {
    display: flex;
    flex-direction: column;
}

label {
    font-family: $font-avenir-medium;
    max-width: 11rem;
    width: 50%;
}

.display-more {
    margin-right: 0.6rem;
    font-family: $font-avenir-medium !important;
}

i {
    position: relative;
    top: 1px;
    cursor: pointer;
}

.block-left {
    border-right: 1px $blue-neutral solid;
    width: 37rem;
    @media all and (min-width: 850px) {
        padding-right: 2em;
    }
}

.block-right {
    padding-top: 4rem;
    padding-left: 7rem;
    max-width: initial;
}

.block-submit {
    margin-top: 2rem;
}

.img-cylinder-details {
    width: 90%;
    min-width: 320px;
    pointer-events: none;
}
.form-elem {
    display: flex;
    margin: 0.7rem 0;
    align-items: center;

    span {
        max-width: 50%;
    }
}

.align-items-initial {
    align-items: initial;
}

.form-input,
.form-textarea {
    flex-grow: 1;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
}

.modal-container {
    width: 300px;
    margin: auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    font-size: $big;
    width: 100%;
    position: relative;
    .cross-close {
        cursor: pointer;
        position: absolute;
        top: -15px;
        right: -16px;
        color: $blue-neutral;
    }
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.justify-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.relative {
    position: relative;
}

.font-bold {
    font-weight: 700;
}
.font-extrabold {
    font-weight: 800;
}
.text-xs {
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
}
.text-sm {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
}
.text-lg {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
}
.text-2xl {
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
}
.orangeneutral {
    color: $orange-neutral;
}
.text-center {
    text-align: center;
}
.title {
    font-family: $font_avenir_black;
    font-size: $veryBig;
    width: 25%;
    margin: 0 !important;
}
.block {
    // padding: 40px 44px 0px 44px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.block-wrapper {
    width: max-content;
}

@media all and (min-width: 1024px) {
    .top-block-container {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 1em;
        .top-block {
            width: 100%;
            max-width: 1448px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
        }
    }
}

@media all and (max-width: 1500px) {
    .img-cylinder-details {
        width: 85%;
    }
}

@media all and (max-width: 1300px) {
    .block-right {
        padding-left: 4rem;
    }
}

@media all and (max-width: 1200px) {
    .block-right {
        padding-left: 4rem;
        padding-top: 4rem;
    }
}

@media all and (max-width: 850px) {
    .block-left {
        border-right: initial;
    }

    .block-right {
        display: none;
    }

    .block-submit {
        display: flex;
        justify-content: center;
    }
}

@media all and (max-width: 600px) {
    .top-block {
        flex-direction: column;
        margin-bottom: 1.5rem;
    }

    .override-option {
        margin-top: 1rem;
    }
}
</style>
