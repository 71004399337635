import httpClient from "../httpClient"
import { encodeRequestParameters, formatData } from "@/services/request"
import { residenceSummaryByIdFormat } from "@/services/formats"

/**
 * Returns a specific stairwell
 */
export function getStairwellById(stairwellId) {
    return httpClient
        .get("/cage/" + stairwellId)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Returns a specific stairwell and global its related informations
 */
export function getStairwellSummaryById(stairwellId) {
    const data = formatData(residenceSummaryByIdFormat)
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/cage/" + stairwellId + "/summary?limit_acces=20", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

// export function getStairwellCountById(stairwellId) {
//     return httpClient
//         .get("/cage/" + stairwellId + "/count")
//         .then((response) => {
//             return response
//         })
//         .catch((error) => {
//             throw error
//         })
// }
