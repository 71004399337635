import httpIntratone from "@/services/httpIntratone.js"
import { nextTick } from "@vue/runtime-core"

export default {
    data() {
        return {
            // URL for request
            pathURL: null,
            // Data for request
            pathData: null,
        }
    },
    methods: {
        goToV1View(path, blank = false, data = '') {
            if (blank == true) {
                let route = this.$router.resolve({ path: "/v1/" + path })
                window.open(route.href, "_blank")
            } else {
                this.$router.push({ path: "/v1/" + path })
            }
        },
        async openV1Modal(path, vZone) {
            // Setting arguments for request
            let pathArguments = path.split("/")
            if (pathArguments[pathArguments.length - 1].includes("=")) {
                this.pathData = pathArguments.pop()
            }
            this.pathURL = pathArguments.join("/") + ".php"

            // TEMP
            let dataWithSID = this.pathData !== null ? `${this.pathData}&` : ""
            dataWithSID += `SID=${this.$store.getters["auth/getIntratoneDev2SID"]}`

            // Requesting v1 site for content
            let v1Response = null
            v1Response = await httpIntratone.post(this.pathURL, dataWithSID)

            // Creating vZone if it does not exist
            if (document.getElementById(vZone) == null) {
                let portationDiv = document.createElement("div")
                portationDiv.id = vZone
                document
                    .getElementById("v1-modal-bloc")
                    .appendChild(portationDiv)
            }

            // Waiting for DOM to be updated
            await nextTick()

            // Appending HTML response
            document.getElementById(vZone).innerHTML = v1Response.data.html

            // Waiting for DOM to be updated
            await nextTick()

            // Appending and executing JS response
            if (v1Response.data.js == "") {
                v1Response.data.js = "hideall();"
            }
            let script = document.createElement("script")
            script.text = v1Response.data.js
            document.head.appendChild(script)
            // eval(v1Response.data.js)
        },
    },
}
